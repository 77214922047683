import 'core-js/stable';
import 'regenerator-runtime/runtime';
import MagicGrid from 'magic-grid';

let overlay = false;
let $clearMobileBusinessesSearchFilter = null;
let $clearMobileProjectsSearchFilter = null;
let $desktopBusinessesDropdownSwitchBusiness = null;
let $ogranizationSwitchDropdown = null;
let $campaignSwitchDropdown = null;
let $mobileBusinessesDropdownSwitchBusiness = null;
let $mobileProjectsDropdownSwitchProject = null;
let $navBusinessDropdownSearchDesktop = null;
let $navProjectDropdownSearchDesktop = null;
let $organizationSearch = null;
let $campaignSearch = null;
const USER_IS_LOGGED_IN = true; // TODO: Pull based on php or content
const nonSupportedBusinessAndProjectSwitchingPages = [
  'campaigncreation',
  'creativereview',
  'packagebuild',
  'packagereview',
  'projectonboarding',
];

$(() => {
  $clearMobileBusinessesSearchFilter = $('.mobile-search.business .clear-filter');
  $clearMobileProjectsSearchFilter = $('.mobile-search.projects .clear-filter');
  $ogranizationSwitchDropdown = $('#organizationFilterList > .switchOrganization');
  $campaignSwitchDropdown = $('#campaignFilterList > .campaign-li');
  $desktopBusinessesDropdownSwitchBusiness = $('#businessDesktopFilterList > .switchBusiness');
  $mobileBusinessesDropdownSwitchBusiness = $('#navBusinessDropdownMobile > .switchBusiness');
  $mobileProjectsDropdownSwitchProject = $('#navProjectsDropdownMobile > .switchProject');
  $navBusinessDropdownSearchDesktop = $('input#navBusinessDropdownSearchDesktop');
  $navProjectDropdownSearchDesktop = $('input#navProjectDropdownSearchDesktop');
  $organizationSearch = $('#organization-search');
  $campaignSearch = $('#campaign-search');

  /**
   *
   *
   *
   * @returns {boolean}
   */
  function pageNotSupportingBusinessAndProjectSwitching() {
    for (const nonSupportedPage of nonSupportedBusinessAndProjectSwitchingPages) {
      if ($('body').hasClass(nonSupportedPage)) {
        return true;
      }
    }

    return false;
  }

  /**
   *
   * INITIAL LOAD EVENTS
   *
   */
  $clearMobileBusinessesSearchFilter.hide();
  $clearMobileProjectsSearchFilter.hide();
  $('.projects-container').hide();

  // Generate dashboard widgets grid
  if ($('#dashboard-widgets').length > 0) {
    const dashboardMagic = new MagicGrid({
      animate: true,
      container: '#dashboard-widgets',
      gutter: 30,
      static: true,
      useMin: true,
    });

    dashboardMagic.listen();
  }
  /** INITIAL LOAD EVENTS -- END */

  /**
   *
   * EVENT TRIGGER HANDLERS
   *
   */

  // MOBILE NAVBAR (ACTIVE ITEM)
  $('#mobileNavBar .menu-item').on('click', (e) => {
    // Check if menu-item that was just clicked is active or not
    if (!$(e.currentTarget).hasClass('active')) {
      // If it wasn't active prior to click
      // remove the active class from all menu-items
      // and apply active class to currently selected menu-item
      $('#mobileNavBar .menu-item').removeClass('active');
      $(e.currentTarget).addClass('active');
    } else {
      // Remove active status because we are attempting
      // to close menu item that was already active
      $(e.currentTarget).removeClass('active');
    }
  });

  if (USER_IS_LOGGED_IN) {
    // Business dropdown
    $('.nav-business').on('shown.bs.dropdown', () => {
      if ($navBusinessDropdownSearchDesktop.length > 0) {
        $navBusinessDropdownSearchDesktop.focus();
      }
    });

    // Start Business Switcher
    $('.switchBusiness').on('click', (e) => {
      e.preventDefault();

      const businessID = parseInt($(e.currentTarget).data('business-id'), 10);
      let projectID = $(e.currentTarget).data('project-id');
      projectID = (projectID == null) ? '' : parseInt(projectID, 10);

      // Update current url string
      let url = new URL(window.location.href);
      url.searchParams.set('business', businessID.toString());
      url.searchParams.set('project', projectID.toString());

      // Handle determining how we perform this switch
      if (pageNotSupportingBusinessAndProjectSwitching()) {
        // To prevent issues where a user switches into a different business in the middle of a stepped process we will in most cases redirect to the homepage
        url.pathname = 'home';
        url = cleanSearchParams(url, ['business', 'project']);
      } else {
        if ($('body').hasClass('kpireporting')) {
          url = cleanSearchParams(url, ['business', 'project']);
        }
        // Allow analytics users to simply swap our their business and project ids and be good
      }

      // Redirect the user to their final destination
      window.location.replace(url.toString());
    });

    // Filter Desktop Business List
    $navBusinessDropdownSearchDesktop.keyup((e) => {
      filterDesktopBusinessList(e.target);
    });

    // Filter Mobile Business List
    $('input#navDropdownSearchMobileBusiness').keyup((e) => {
      filterMobileBusinessList(e.target);
    });

    // End Business Switcher

    // Projects dropdown
    $('.nav-projects').on('shown.bs.dropdown', () => {
      if ($navProjectDropdownSearchDesktop.length > 0) {
        $navProjectDropdownSearchDesktop.focus();
      }
    });

    // Start Project Switcher
    $('.switchProject').on('click', (e) => {
      e.preventDefault();

      let projectID = $(e.currentTarget).data('project-id');
      projectID = (projectID == null) ? '' : parseInt(projectID, 10);
      const projectStatus = $(e.currentTarget).data('project-status');
      let finalDestination = '';

      // Handle determining how we perform this switch
      if (projectStatus === 'pending') {
        finalDestination = $(e.currentTarget).attr('href');
      } else {
        let url = new URL(window.location.href);
        url.searchParams.set('project', projectID.toString());

        if (pageNotSupportingBusinessAndProjectSwitching()) {
          // To prevent issues where a user switches into a different business in the middle of a stepped process we will in most cases redirect to the homepage
          url.pathname = 'home';
          url = cleanSearchParams(url, ['business', 'project']);
        } else {
          if ($('body').hasClass('kpireporting')) {
            url = cleanSearchParams(url, ['business', 'project']);
          }
          // Allow analytics users to simply swap our their business and project ids and be good
        }

        finalDestination = url.toString();
      }

      // Redirect the user to their final destination
      window.location.replace(finalDestination);
    });

    // Filter Desktop Projects List
    $('#navProjectDropdownSearchDesktop').on('keyup', (e) => {
      e.preventDefault();

      const q = $(e.currentTarget).val().toLowerCase();
      if (q.length >= 2) {
        $('.switchProject', '#navProjectDropdownDesktop').each((_, element) => {
          const name = $(element).find('.project_name').html().toLowerCase();

          if (name.indexOf(q) >= 0) {
            $(element).show();
          } else {
            $(element).hide();
          }
        });
      } else {
        $('.switchProject', '#navProjectDropdownDesktop').show();
      }
    });

    // Filter Mobile Projects List
    $('input#navDropdownSearchMobileProject').keyup((e) => {
      filterMobileProjectList(e.currentTarget);
    });

    // End Project Switcher
  }

  // Listening for button click on nav bar
  $('.desktop-nav button, .d-none a').click((e) => {
    // If hamburger menu is clicked set flag to true, to display overlay
    const flag = $(e.currentTarget).hasClass('main-nav-dropdown');

    if (flag) {
      toggleOverlay(true, overlay);
    } else {
      // Hide overlay
      toggleOverlay(false, true);
    }
  });

  // Hide overlay on click of document
  $(document).click(() => {
    toggleOverlay(false, true, true);
  });

  $('.nav-search .form-control').click(() => {
    $('#nav-table').removeClass('show');
  });

  // PROJECTS CONTAINER
  $('.show-projects').click(() => {
    // Hide business-container
    $('.business-container').hide();
    // Hide more-container
    $('.more-container').hide();
    // Toggle projects container
    $('.projects-container').slideToggle('slow', () => {
      if ($('.show-projects').hasClass('active')) {
        $('#navDropdownSearchMobileProject').focus();
      }
    });
  });

  // BUSINESS CONTAINER
  $('.show-business').click(() => {
    // Hide business-container
    $('.projects-container').hide();
    // Hide more-container
    $('.more-container').hide();
    // Slide business-container up
    $('.business-container').removeClass('hide').slideToggle('slow', () => {
      if ($('.show-business').hasClass('active')) {
        $('#navDropdownSearchMobileBusiness').focus();
      }
    });
  });

  // MORE CONTAINER
  $('.show-more').click(() => {
    // Add class to business container
    $('.business-container').hide();
    $('.projects-container').hide();

    // Slide more-container up
    $('.more-container').slideToggle('slow');
  });

  // CLOSE BUSINESS/MORE CONTAINERS
  $('.close-more').click(() => {
    $('.more-container').slideUp('slow');
  });

  $('.close-business').click(() => {
    $('.business-container').slideUp('slow');
  });

  $('.close-projects').click(() => {
    $('.projects-container').slideUp('slow');
  });

  // Start Business Switcher
  $('#systemAlerts button.close').on('click', (e) => {
    const $alert = $(e.currentTarget).closest('.alert');
    const systemAlertID = $alert.attr('id').replace('systemAlert-', '');

    // Acknowledge the alert for this user
    $.ajax({
      url: `/system-alert/${systemAlertID}/acknowledge`,
      type: 'get',
      dataType: 'json',
    }).done(() => {}).fail(() => {}).then(() => {});
  });

  $organizationSearch.keyup((e) => {
    filterOrganizationList(e.target);
  });

  $campaignSearch.keyup((e) => {
    filterCampaignList(e.target);
  });

  /** EVENT TRIGGER HANDLERS -- END */
});

/**
 *
 * FUNCTIONS
 *
 */

/**
 * Remove any url search params that are not in a whitelist array.
 *
 * @param {URL} url
 * @param {array} whitelist
 * @returns {*}
 */
function cleanSearchParams(url, whitelist) {
  const cleanUrl = new URL(url.toString());

  for (const [p] of cleanUrl.searchParams) {
    if (p != null && whitelist.indexOf(p) === -1) {
      cleanUrl.searchParams.delete(p);
    }
  }

  // Some strings that exist in the URL's query params will not appear in cleanUrl.searchParams and will have to be removed by specifically calling them
  cleanUrl.searchParams.delete('step');

  return cleanUrl;
}

/**
 *
 * @param element
 */
function filterOrganizationList(element) {
  const value = $(element).val().toLowerCase();

  if (value.length >= 2) {
    $ogranizationSwitchDropdown.each((i, elem) => {
      if ($(elem).text().toLowerCase().indexOf(value) > -1) {
        $(elem).show();
      } else {
        $(elem).hide();
      }
    });
  } else {
    $ogranizationSwitchDropdown.show();
  }
}

/**
 *
 * @param element
 */
function filterCampaignList(element) {
  const value = $(element).val().toLowerCase();

  if (value.length >= 2) {
    $campaignSwitchDropdown.each((i, elem) => {
      if ($(elem).text().toLowerCase().indexOf(value) > -1) {
        $(elem).show();
      } else {
        $(elem).hide();
      }
    });
  } else {
    $campaignSwitchDropdown.show();
  }
}

/**
 *
 * @param element
 */
function filterDesktopBusinessList(element) {
  const value = $(element).val().toLowerCase();

  if (value.length >= 2) {
    $desktopBusinessesDropdownSwitchBusiness.each((i, elem) => {
      if ($(elem).text().toLowerCase().indexOf(value) > -1) {
        $(elem).show();
      } else {
        $(elem).hide();
      }
    });
  } else {
    $desktopBusinessesDropdownSwitchBusiness.show();
  }
}

/**
 *
 * @param element
 */
function filterMobileBusinessList(element) {
  const value = $(element).val().toLowerCase();

  if (value.length >= 2) {
    $clearMobileBusinessesSearchFilter.show();

    $mobileBusinessesDropdownSwitchBusiness.each((i, elem) => {
      if ($(elem).text().toLowerCase().indexOf(value) > -1) {
        $(elem).show();
      } else {
        $(elem).hide();
      }
    });
  } else {
    $mobileBusinessesDropdownSwitchBusiness.show();
    $clearMobileBusinessesSearchFilter.hide();
  }

  // Clear Filter and show entire list
  $clearMobileBusinessesSearchFilter.click(() => {
    $(element).val('');
    $mobileBusinessesDropdownSwitchBusiness.show();
  });
}

/**
 *
 * @param element
 */
function filterMobileProjectList(element) {
  const value = $(element).val().toLowerCase();

  if (value.length >= 2) {
    $clearMobileProjectsSearchFilter.show();

    $mobileProjectsDropdownSwitchProject.each((i, elem) => {
      if ($(elem).text().toLowerCase().indexOf(value) > -1) {
        $(elem).show();
      } else {
        $(elem).hide();
      }
    });
  } else {
    $clearMobileProjectsSearchFilter.hide();
    $mobileProjectsDropdownSwitchProject.show();
  }

  // Clear Filter and show entire list
  $clearMobileProjectsSearchFilter.click((e) => {
    $(element).val($(e.currentTarget).val());
    $mobileProjectsDropdownSwitchProject.show();
  });
}

/**
 * Toggle the nav overlay visibility.
 *
 * @param toggle
 * @param overlayFlag
 * @param documentClick
 */
function toggleOverlay(toggle, overlayFlag, documentClick) {
  // If we want to show overlay and overlay is not already showing
  // overlayFlag is passed because we do not want to show overlay when main nav collapses
  if (toggle && !overlayFlag) {
    $('#nav-table-overlay').show();
  } else {
    $('#nav-table-overlay').hide();
  }

  if (!documentClick) {
    // Change overlay flag
    overlay = !overlayFlag;
  } else {
    overlay = false;
  }
}
